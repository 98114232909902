<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <div class="d-flex align-items-center row">
          <div class="col-md-6 d-flex align-items-center">
            <span style="font-size: 16px; text-transform: uppercase; font-weight: 600;">{{ model.NrR }}</span>
          </div>
          <div class="nav col-md-6 d-flex justify-content-end">
            <el-button
                @click="createFV"
                v-if="model.Rej === 'PRO'"
            >
              {{ $t('system.create_FV') }}
            </el-button>
            <el-button
                @click="$router.push('/invoice/' + model.id + '/edit')"
                type="info"
                v-if="ifHasPermission(['edit-invoice'])"
            >{{ $t('system.edit') }}
            </el-button>
            <el-button
                @click="generatePDF"
                plain
                type="info"
                v-if="ifHasPermission(['invoice-generate'])"
            >{{ $t('system.pdf') }}
            </el-button>
            <el-button
                @click="remove"
                type="danger"
                v-if="ifHasPermission(['delete-invoice'])"
            >{{ $t('system.delete') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-3 normal-width">
              <label style="font-weight: bold;">{{ $t('invoice.dealer') }}:</label>
              <address>
                <p class="no-margin-bottom">{{ setting.client_name ? setting.client_name : '' }}</p>
                <p class="no-margin-bottom">{{ setting.nip ? setting.nip : '' }}</p>
                <p class="no-margin-bottom">{{ setting.street ? setting.street : '' }}</p>
                <p class="no-margin-bottom">{{ setting.post_code ? setting.post_code : '' }}</p>
                <p class="no-margin-bottom">{{ setting.city ? setting.city : '' }}</p>
              </address>
            </div>
            <div class="col-sm-4">
              <label style="font-weight: bold;">{{ $t('invoice.recipient') }}:</label>
              <address>
                <!-- <label>{{ $t('invoice.recipient_alias') }}</label> -->
                <p class="no-margin-bottom">{{ model.Alias ? model.Alias : '' }}</p>
                <!-- <label>{{ $t('invoice.recipient_nip') }}</label> -->
                <p class="no-margin-bottom">{{ model.NIP ? model.NIP : '' }}</p>
                <!-- <label>{{ $t('invoice.recipient_nazw') }}</label> -->
                <!--<p>{{model.nazw ? model.nazw : ''}}</p>-->
                <!-- <label>{{ $t('invoice.recipient_ulic') }}</label> -->
                <p class="no-margin-bottom">{{ model.Ulic ? model.Ulic : '' }}</p>
                <!-- <label>{{ $t('invoice.recipient_kodp') }}</label> -->
                <p class="no-margin-bottom">{{ model.Kodp ? model.Kodp : '' }}</p>
                <!-- <label>{{ $t('invoice.recipient_mias') }}</label> -->
                <p class="no-margin-bottom">{{ model.Mias ? model.Mias : '' }}</p>
              </address>
            </div>
            <div class="col-sm-4">
              <address>
                <label style="font-weight: bold;">{{ $t('invoice.recipient_dwy') }}</label>
                <p class="no-margin-bottom">{{ model.DWy ? model.DWy : '' }}</p>
                <label style="font-weight: bold;">{{ $t('invoice.recipient_dsp') }}</label>
                <p class="no-margin-bottom">{{ model.DSp ? model.DSp : '' }}</p>
                <label style="font-weight: bold;">{{ $t('invoice.recipient_tpl') }}</label>
                <p class="no-margin-bottom">{{ model.Tpl ? model.Tpl : '' }}</p>
              </address>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>{{ $t('report.nazw') }}</th>
                <th>{{ $t('report.il') }}</th>
                <th>{{ $t('report.jm') }}</th>
                <th>{{ $t('report.prrab') }}</th>
                <th>{{ $t('report.before_discount') }}</th>
                <th>{{ $t('report.cn') }}</th>
                <th>{{ $t('report.value_cn') }}</th>
                <th>{{ $t('report.nvat') }}</th>
                <th>{{ $t('report.vat') }}</th>
                <th>{{ $t('report.total') }}</th>
              </tr>
              </thead>
              <thead>
              <tr
                  v-bind:key="item.id"
                  v-for="item in model.items"
              >
                <td>{{ item.Nazw }}</td>
                <td>{{ item.il | numberChangeSeparator }}</td>
                <td>{{ item.JM }}</td>
                <td>{{ item.PrRab | numberChangeSeparator }}</td>
                <td>{{ item.Cena | numberChangeSeparator }}</td>
                <td>{{ item.CN | numberChangeSeparator }}</td>
                <td>{{ item.valueCN | numberChangeSeparator }}</td>
                <td>{{ item.VAT }}%</td>
                <td>{{ item.valueVAT | numberChangeSeparator }}</td>
                <td>{{ item.cenab | numberChangeSeparator }}</td>
              </tr>
              </thead>
              <tfoot>
              <tr></tr>
              <tr></tr>
              <tr>
                <td colspan="5">
                </td>
                <td>{{ $t('report.total') }}</td>
                <td>{{ model.N0 + model.N1 + model.N2 + model.N3 | numberChangeSeparator }}</td>
                <td></td>
                <td>{{ model.V1 + model.V2 + model.V4 | numberChangeSeparator }}</td>
                <td>{{ model.Wart | numberChangeSeparator }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
          <hr />
          <div class="row text-right">
            <div class="col-sm-12">
              <p>
                <label style="font-weight: bold;">{{ $t('invoice.wart') }}:</label>
                {{
                  model.Wart ? model.Wart :
                  '' | numberChangeSeparator
                }}
              </p>
              <p>
                <label style="font-weight: bold;">{{ $t('invoice.slownie') }}:</label>
                {{
                  model.slownie ?
                  model.slownie : ''
                }}
              </p>
              <p>
                <label style="font-weight: bold;">{{ $t('invoice.fp') }}:</label>
                {{ model.Fp ? model.Fp : '' }}
              </p>
              <p>
                <label style="font-weight: bold;">{{ $t('invoice.bankn') }}:</label>
                {{
                  setting.BankN ?
                  setting.BankN : ''
                }}
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <div
            class="panel panel-default"
            v-if="time_entries"
        >
          <div class="panel-heading">
            <div class="col-sm-3 no-padding-left">
              {{ $t('system.show_attachment') }}
              <b> {{ model.show_attachment ? $t('system.yes') : $t('system.no') }}</b>
            </div>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="no-padding">
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover no-pointer">
                      <thead>
                      <tr>
                        <th style="width: 20%">{{ $t('report.project_name') }}</th>
                        <th style="width: 20%">{{ $t('report.issue_number') }}</th>
                        <!--<th style="width: 10%">{{ $t('report.issue_estimation') }}</th>-->
                        <!--<th style="width: 10%">{{ $t('report.user') }}</th>-->
                        <!--<th style="width: 10%">{{ $t('report.author') }}</th>-->
                        <!--<th style="width: 10%" v-if="modules['activity'] == 1">{{ $t('report.activity') }}</th>-->
                        <!--<th style="width: 10%">{{ $t('report.issue_status') }}</th>-->
                        <!--<th>{{ $t('report.date_settled') }}</th>-->
                        <th style="width: 5%">{{ $t('report.time_entires_created') }}</th>
                        <th style="width: 5%">{{ $t('report.time_entires_hours') }}</th>
                        <th style="width: 30%">{{ $t('report.comments') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(view, path) in time_entries">
                        <tr
                            v-bind:key="path"
                            v-if="view.issue"
                        >
                          <td>
                            <router-link :to="'/project/' + view.issue.project_id">
                              {{ view.issue.project.name ? view.issue.project.name : '' }}
                            </router-link>
                          </td>
                          <td>
                            #{{ view.issue.id ? view.issue.id : '' }} {{
                              view.issue.subject ?
                              view.issue.subject : ''
                            }}
                          </td>
                          <!--<td>{{view.issue ? view.issue.estimated_hours : ''}}</td>-->
                          <!--<td>{{view.user ? view.user.name : ''}}</td>-->
                          <!--<td>{{view.issue.author ? view.issue.author.name : ''}}</td>-->
                          <!--<td v-if="modules['activity'] == 1">-->
                          <!--{{view.activity ? (view.activity ? view.activity.name : '') : ''}}-->
                          <!--</td>-->
                          <!--<td>{{view.issue.status ? view.issue.status.enumeration.name : ''}}</td>-->
                          <!--<td>-->
                          <!--{{view.settled_internal ? view.settled_internal : ''}}-->
                          <!--</td>-->
                          <td>{{ view.spent_on ? getDate(view.spent_on) : '' }}</td>
                          <td>{{ view.overworked_time ? view.overworked_time : '' }}</td>
                          <td>{{ view.comments ? view.comments : '' }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';
import notification from '../../notification/notify';

export default {
  name: 'InvoiceShow',
  filters: {
    numberChangeSeparator: function (number) {
      if (number) {
        return number.toString().replace('.', ',');
      }
      return number;
    },
  },
  data() {
    return {
      model: {
        items: [],
        getTotalVAT: 0,
        getTotalCN: 0,
      },
      project_details: {
        client_name: null,
        nip: null,
        street: null,
        post_code: null,
        city: null,
      },
      resource: 'invoice',
      redirect: '/invoice',
      modules: {},
      time_entries: {},
      setting: {
        Bank: null,
        BankN: null,
      },
    };
  },
  beforeMount() {
    this.fetchData();
  },
  mounted() {
    this.modules = this.$store.getters.modules;
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    remove() {
      var vm = this;

      swal.fire({
        title: vm.$t('system.if_you_sure'),
        text: vm.$t('system.if_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
        focusCancel: true,
      }).then((result) => {
        if (result.value) {
          axios.delete(`${vm.resource}/${vm.$route.params.invoiceId}`)
              .then(response => {
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.deleted'),
                    'success');
                vm.$router.push(vm.redirect);
              })
              .catch(error => {
                notification.notify(
                    vm.$t('notify.error'),
                    error.message,
                    'error');
              });
        }
      });
    },
    fetchData() {
      const vm = this;
      axios.get(`${this.resource}/${this.$route.params.invoiceId}`)
          .then(function (response) {
            vm.model = response.data.model;
            vm.setting = response.data.settings;
            vm.project_details = response.data.project_details;
            vm.time_entries = response.data.time_entries;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    generatePDF() {
      var vm = this;
      axios.get(`${this.resource}/generate/${this.$route.params.invoiceId}`, {
            responseType: 'blob',
          },
      )
          .then(function (response) {
            const a = document.createElement('a');
            let blob = null;
            document.body.appendChild(a);
            a.style = 'display: none';
            blob = new Blob([response.data], {type: response.headers['content-type']});
            let filename = (response.headers['content-disposition'] || '').split('filename=')[1].replace(/["']/g, '');
            // let filename = '_' + Math.random().toString(36).substr(2, 9) + '.' + response.config.params.type;
            let url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(blob => {
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    createFV() {
      var vm = this;
      swal.fire({
        title: vm.$t('system.createFV'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          if (!_.isEmpty(vm.time_entries)) {
            let timeEntriesIds = vm.time_entries.map(function (a) {
              return a.id;
            });
            vm.$store.commit('SET_INVOICE_ITEMS', timeEntriesIds);
          }
          vm.$router.push({
            path: `/invoice/${vm.model.id}/create`,
          });
        }
      }).catch();
    },
    getDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    getTime(date) {
      return date.substring(0, date.length - 3);
    },
  },
};
</script>
